import React, { useState } from 'react';
import '../css/ContactSection.css';
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setResponseMessage(''); 

    try {
      const response = await fetch('https://app.furloop.io/api/reseller/enquiry.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setResponseMessage(data.message);
        setIsSuccess(true);
        setFormData({ // Reset form fields
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: ''
        });

        // Hide success message after 5 seconds
        setTimeout(() => {
          setResponseMessage('');
        }, 5000);

      } else {
        setResponseMessage(data.message);
        setIsSuccess(false); 
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setResponseMessage('Failed to submit the form. Please try again later.');
      setIsSuccess(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="main-section-header">
      <div className="main-container">
        <div className="w-layout-grid grid_1-2 _75-space">
          <div className="content-wrap">
            <h2 className="trade-in-header">Any Questions?</h2>
            <div className="spacer_m"></div>
            <p className="paragraph">We will get back to you within 1 business day.</p>
            <div className="spacer_l"></div>
            <a href="mailto:help@furloop.io?subject=New%20Waitlist%20Signup" className="button secondary w-button">Email Us</a>
          </div>
          <div className="w-form">
           {responseMessage && (
              <div
                className="response-message"
                style={{
                  backgroundColor: isSuccess ? 'green' : 'red',
                  color: 'white',
                  padding: '1rem',
                  borderRadius: '5px',
                  marginBottom: '1rem',
                }}
              >
                {responseMessage}
              </div>
            )}
            <form onSubmit={handleSubmit} className="form-inner">
              <div className="flex-row_outer">
                <div className="input-wrapper _15p-right">
                  <label htmlFor="First-Name-2" className="input-label">First Name</label>
                  <input
                    className="input w-input"
                    maxLength="256"
                    placeholder="Enter first name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    type="text"
                    id="First-Name-2"
                    required
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="Last-Name-2" className="input-label">Last Name</label>
                  <input
                    className="input w-input"
                    maxLength="256"
                    placeholder="Enter last name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    type="text"
                    id="Last-Name-2"
                    required
                  />
                </div>
              </div>
              <div className="flex-row_outer">
                <div className="input-wrapper _15p-right">
                  <label htmlFor="Email-3" className="input-label">Email</label>
                  <input
                    className="input w-input"
                    maxLength="256"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    type="email"
                    id="Email-3"
                    required
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="Phone-3" className="input-label">Phone</label>
                  <input
                    className="input w-input"
                    maxLength="256"
                    placeholder="Enter phone no."
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    type="tel"
                    id="Phone-3"
                    required
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label htmlFor="field-4" className="input-label">Message</label>
                <textarea
                  id="field-4"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  maxLength="5000"
                  placeholder="Any message for our team is welcomed."
                  className="input text-area w-input"
                  required
                ></textarea>
              </div>
              <div className="spacer_xs"></div>
              <input
                type="submit"
                className="button in-form w-button"
                value={submitting ? 'Submitting...' : 'Submit'}
                disabled={submitting}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
