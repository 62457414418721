import React from 'react';
import '../css/Brand.css';
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css'; 




const logos = [
  "https://cdn.shopify.com/s/files/1/0612/1875/0717/files/PlaySide_7a0e8330-e99e-47ba-94ad-417e44cb686c.jpg?v=1684329192",
  "https://cdn.shopify.com/s/files/1/0612/1875/0717/files/Hardie_Grant.jpg?v=1684329192",
  "https://cdn.shopify.com/s/files/1/0612/1875/0717/files/Today.jpg?v=1684329192",
  "https://cdn.shopify.com/s/files/1/0612/1875/0717/files/Keypath_adf96fe2-75d7-480b-a872-b949bc0cf96f.jpg?v=1684329192",
  "https://cdn.shopify.com/s/files/1/0612/1875/0717/files/Comedy_Festival.jpg?v=1684329192"
];

const Brand = () => {
  return (

    
<>

<div className="logo-carousel-container">
      <div className="logo-carousel-track">
        {/* Render the logos twice for a seamless loop */}
        {logos.map((logo, index) => (
          <div key={index} className="logo-item">
            <img src={logo} alt={`Logo ${index}`} />
          </div>
        ))}
        {logos.map((logo, index) => (
          <div key={index + logos.length} className="logo-item">
            <img src={logo} alt={`Logo ${index}`} />
          </div>
        ))}
        {logos.map((logo, index) => (
          <div key={index + logos.length} className="logo-item">
            <img src={logo} alt={`Logo ${index}`} />
          </div>
        ))}

{logos.map((logo, index) => (
          <div key={index + logos.length} className="logo-item">
            <img src={logo} alt={`Logo ${index}`} />
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default Brand;
