import React from 'react';
import '../css/HeroSection.css'; 
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';



const HeroSection = () => {

  // List of words to rotate
  const words = ['Buyback', 'Trade In', 'Consign', 'Purchase'];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  // Change the word every 2 seconds (2000 milliseconds)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1500); // Adjust timing here

    return () => clearInterval(intervalId); // Cleanup the interval on unmount
  }, [words.length]);

  return (

<div id="main-section-edit" className="main-section-header-title">
        <section className="section-intro">
          <div className="w-layout-blockcontainer container-3 w-container">
            <h1 className="heading-2">Automate Your <span className="gradeint-header">{words[currentWordIndex]}</span><br />Process with FurLoop</h1>
            <div className="w-layout-blockcontainer container-5 w-container">
              <div className="div-block-6"><img src="https://cdn.prod.website-files.com/648422913bdb661f6232a4c4/669e56eeb1d5f08a90d47d7d_Icon.svg" loading="lazy" alt="" /></div>
            </div>
          </div>
          <div className="text-block">Simply embed the widget on your website, upload your products and pricing and <br />start your buyback in less than 30 mins</div>
        </section>
        <div className="div-block-2"></div>
        <Link to="/sign-up-today" className="btn btn-active btn-primary">Sign Up for free!</Link>

       
        <div className="div-block-4"></div>
        <div className="w-layout-blockcontainer container-6 w-container">
          <div className="div-block-6"><img src="https://cdn.prod.website-files.com/648422913bdb661f6232a4c4/669e5aa2dff36e32b9051c06_Icon%20(1).svg" loading="lazy" alt="" /></div>
        </div>
        <div className="div-block-3"></div>
      </div>

   
  );
};

export default HeroSection;
