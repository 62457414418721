import React from 'react';
import '../css/FeatureSection.css';
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css'; 
import image1 from '../images/Group-1000002442.svg';
import image2 from '../images/Group-1000002444.svg';
import image3 from '../images/Group-1000002445.svg';
import image4 from '../images/Group-1000002457.svg';
import image5 from '../images/Group-1000002451-2.svg';
import image6 from '../images/Group-1000002452.svg';
import image7 from '../images/Group-1000002454.svg';
import image8 from '../images/Group-1000002453.svg';
import image9 from '../images/Group-1000002457-1.svg';

const FeatureSection = () => {
  return (

    <>
<div className="function-section">
      <div className="main-container">
        <div className="content-wrap_center">
          <div
            id="w-node-_83fcacf8-2a0e-9f28-79b7-39a6ab7e6744-6232a524"
            data-w-id="83fcacf8-2a0e-9f28-79b7-39a6ab7e6744"
            style={{
              opacity: 1,
              transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
              transformStyle: 'preserve-3d'
            }}
            className="content-wrap_center mw-800"
          >
            <h2 className="heading-integration-head-1">Easy integrations with</h2>
            <h2 className="trade-in-header">FurLoop</h2>
            <div className="spacer_xs"></div>
            <p className="paragraph-intro"></p>
          </div>
          <div className="spacer_xxl"></div>
        </div>
        <div className="blur-overlay blur-left"></div>
  
  {/* Right blur overlay */}
  <div className="blur-overlay blur-right"></div>
        <div className="w-layout-blockcontainer container-multi w-container">
          <section data-w-id="83fcacf8-2a0e-9f28-79b7-39a6ab7e674d" className="div-function-1">
            <address className="w-layout-hflex flex-block-upper">
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image1} loading="lazy" width="45" alt="Boost Revenue and Profitability" />
                  <p className="function-text">Boost Revenue and Profitability</p>
                </div>
              </div>
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image2} loading="lazy" width="45" alt="Increase Efficiency" />
                  <p className="function-text">Increase Efficiency</p>
                </div>
              </div>
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image3} loading="lazy" width="45" alt="Faster Turnaround" />
                  <p className="function-text">Faster Turnaround</p>
                </div>
              </div>
            </address>
          </section>
          
          <section className="div-function-1">
            <address className="w-layout-hflex flex-block-upper">
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image4} loading="lazy" width="45" alt="Greater Accuracy" />
                  <p className="function-text">Greater Accuracy</p>
                </div>
              </div>
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image5} loading="lazy" width="45" alt="Quick Set-Up" />
                  <p className="function-text">Quick Set-Up</p>
                </div>
              </div>
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image6} loading="lazy" width="45" alt="Customisable Branding" />
                  <p className="function-text">Customisable Branding</p>
                </div>
              </div>
            </address>
          </section>
          
          <section data-w-id="9454c941-67ce-602e-009a-fc72e6da779f" className="div-function-2">
            <address className="w-layout-hflex flex-block-upper">
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image7} loading="lazy" width="45" alt="Accessible from any device" />
                  <p className="function-text">Accessible from any device</p>
                </div>
              </div>
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image8} loading="lazy" width="45" alt="Seamless integrations" />
                  <p className="function-text">Seamless integrations</p>
                </div>
              </div>
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image9} loading="lazy" width="45" alt="Automated data import" />
                  <p className="function-text">Automated data import</p>
                </div>
              </div>
            </address>
          </section>
        </div>


        
        <div className="w-layout-blockcontainer container-multi-mobile w-container">
          <section data-w-id="c30b8074-5499-ea13-6559-5e8628d8d053" className="div-function-1">
            <address className="w-layout-hflex flex-block-upper">
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image1} loading="lazy" width="30" alt="Boost Revenue and Profitability" />
                  <p className="function-text">Boost Revenue and Profitability</p>
                </div>
              </div>
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image2} loading="lazy" width="30" alt="Increase Efficiency" />
                  <p className="function-text">Increase Efficiency</p>
                </div>
              </div>
            </address>
          </section>

          <section data-w-id="c3ad0d83-64c7-fa5f-05c3-d5d7588d0b9e" className="div-function-1">
            <address className="w-layout-hflex flex-block-upper">
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image3} loading="lazy" width="30" alt="Faster Turnaround" />
                  <p className="function-text">Faster Turnaround</p>
                </div>
              </div>
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image4} loading="lazy" width="30" alt="Greater Accuracy" />
                  <p className="function-text">Greater Accuracy</p>
                </div>
              </div>
            </address>
          </section>

          <section data-w-id="c30b8074-5499-ea13-6559-5e8628d8d061" className="div-function-1">
            <address className="w-layout-hflex flex-block-upper">
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image5} loading="lazy" width="30" alt="Quick Set-Up" />
                  <p className="function-text">Quick Set-Up</p>
                </div>
              </div>
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image6} loading="lazy" width="30" alt="Customisable Branding" />
                  <p className="function-text">Customisable Branding</p>
                </div>
              </div>
            </address>
          </section>
          
          <section className="div-function-2">
            <address className="w-layout-hflex flex-block-upper">
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image7} loading="lazy" width="30" alt="Accessible from any device" />
                  <p className="function-text">Accessible from any device</p>
                </div>
              </div>
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image8} loading="lazy" width="30" alt="Seamless integrations" />
                  <p className="function-text">Seamless integrations</p>
                </div>
              </div>
            </address>
          </section>

          <section className="div-function-1">
            <address className="w-layout-hflex flex-block-upper">
              <div className="div-block-7">
                <div className="div-block-8">
                  <img src={image9} loading="lazy" width="30" alt="Automated data import" />
                  <p className="function-text">Automated data import</p>
                </div>
              </div>
            </address>
          </section>
        </div>
      </div>
    </div>
    </>
  );
};

export default FeatureSection;
