import React, { useState } from 'react';
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css'; 
import image1 from '../images/Group-1000002493.svg'; 
import image2 from '../images/Group-1000002495.svg';
import { Link } from 'react-router-dom';

const PriceSection = () => {
  const [activeTab, setActiveTab] = useState('monthly');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
    <div className="content">
      <div className="container">
        <div className="content-wrap-2">
          <div className="content-heading div-block">
            <h1 className="heading-pricing">Choose a plan and start your trade in business today</h1>
          </div>
          <div className="content-text">
            <h1 className="heading-2-pricing">30-Day <span className="gradeint-header-pricing">Free Trial</span><br /></h1>
          </div>
          <div
            data-current="Tab 1"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            className="tabs w-tabs"
          >
            <div className="tabs-menu-2 w-tab-menu"  id="pricing">
              <a data-w-tab="Tab 1" className={`tab-link-monthly w-inline-block w-tab-link ${activeTab === 'monthly' ? 'w--current' : ''}`}
                onClick={() => handleTabClick('monthly')}>
                
                <div>Pay Monthly</div>
              </a>
              <a data-w-tab="Tab 2" className={`tab-link-yearly w-inline-block w-tab-link ${activeTab === 'yearly' ? 'w--current' : ''}`}
                onClick={() => handleTabClick('yearly')}>
                <div>Pay Yearly</div>
              </a>
            </div>
            <div className="tabs-content-2 w-tab-content">
              <div data-w-tab="Tab 1" className={`monthly w-tab-pane ${activeTab === 'monthly' ? 'w--tab-active' : ''}`}>
                <div className="content-cards">
                  {/* Card 1 */}
                  <div className="card">
                    <div className="card-wrap">
                      <div className="card-top">
                        <div className="card-heading">
                          <h2 className="mb-20">Basic</h2>
                          <p className="paragraph-6">Ideal for retail stores just getting started with buybacks</p>
                        </div>
                        <div className="card-price">
                          <p className="mb-5"><span className="p-22">$29</span>/mo</p>
                        </div>
                      </div>
                      <div className="card-bot">
                        <div className="check">
                          <img loading="lazy" src={image1} alt="" />
                          <p><span className="purple-holder">Up to 5 team members</span></p>
                        </div>
                        <div className="check">
                          <img loading="lazy" src={image1} alt="" />
                          <p><span className="purple-holder">Up to 5 Store Locations</span></p>
                        </div>
                        <div className="check">
                          <img loading="lazy" src={image1} alt="" />
                          <p className="purple-holder"><span className="text-span-5">20GB Storage</span></p>
                          
                        </div>
                        <div className="check no-mb">
                          <img loading="lazy" src={image1} alt="" />
                          <p><span className="text-span-6">Website Widget</span></p>
                        </div>
                        <div className="check no-mb">
                          <img loading="lazy" src={image1} alt="" />
                          <p className="paragraph-4"><span className="text-span-7">Unlimited Product Variants</span></p>
                        </div>
                        <div className="check no-mb">
                          <img loading="lazy" src={image1} alt="" />
                          <p><span className="text-span-8">Basic API Integrations</span></p>
                        </div>
                        <div className="check no-mb">
                          <img loading="lazy" src={image1} alt="" />
                          <p><span className="text-span-20">iFrame Integration</span></p>
                        </div>
                      </div>
                      <Link data-ms-price-update="prc_basic-ytuo0uc9" to="/sign-up-today" className="button-2 big w-inline-block">
                        <p>Get Started</p>
                      </Link>
                    </div>
                  </div>
                  
                  {/* Card 2 */}
                  <div className="card mid">
                    <div className="card-wrap mid">
                      <div className="card-top">
                        <div className="card-heading">
                          <h1 className="heading-2"><span className="gradeint-header-pricing-medium">Plus</span><br /></h1>
                          <p className="paragraph-7">Perfect for retail or online companies looking to grow their buyback program</p>
                        </div>
                        <div className="card-price">
                          <p className="mb-5"><span className="p-22">$65</span>/mo</p>
                        </div>
                      </div>
                      <div className="card-bot">
                        <div className="check">
                          <img loading="lazy" src={image2} alt="" />
                          <p><span className="purple-holder">Up to 20 team members</span></p>
                        </div>
                        <div className="check">
                          <img loading="lazy" src={image2} alt="" />
                          <p><span className="purple-holder">Unlimited Store Locations</span></p>
                        </div>
                        <div className="check">
                          <img loading="lazy" src={image2} alt="" />
                          <p className="purple-holder"><span className="text-span-2">100GB Storage</span></p>
                          
                        </div>
                        <div className="check no-mb">
                          <img loading="lazy" src={image2} alt="" />
                          <p><span className="text-span-16">Everything in Basic</span></p>
                        </div>
                        <div className="check no-mb">
                          <img loading="lazy" src={image2} alt="" />
                          <p><span className="text-span-17">24/7 priority support</span></p>
                        </div>
                        <div className="check no-mb">
                          <img loading="lazy" src={image2} alt="" />
                          <p><span className="text-span-18">Bulk Import/ Export</span></p>
                        </div>
                        <div className="check no-mb">
                          <img loading="lazy" src={image2} alt="" />
                          <p><span className="text-span-19">Remove FurLoop branding</span></p>
                        </div>
                      </div>
                      <Link data-ms-price-update="prc_plus-ltin0qul" to="/sign-up-today" className="button-2 big w-inline-block">
                        <p>Get Started</p>
                      </Link>
                    </div>
                  </div>

                  {/* Card 3 */}
                  <div className="card">
                    <div className="card-wrap">
                      <div className="card-top">
                        <div className="card-heading">
                          <h2 className="mb-20">Enterprise</h2>
                          <p className="paragraph-8">For processors, marketplaces, resellers & enterprise organisations looking for fully white label.</p>
                        </div>
                        <div className="card-price">
                          <p className="mb-5"><span className="p-22">Contact </span>Sales</p>
                        </div>
                      </div>
                      <div className="card-bot">
                        <div className="check">
                          <img loading="lazy" src={image1} alt="" />
                          <p><span className="purple-holder">Everything in Plus</span></p>
                        </div>
                        <div className="check">
                          <img loading="lazy" src={image1} alt="" />
                          <p><span className="purple-holder">Custom Installation</span></p>
                        </div>
                        <div className="check">
                          <img loading="lazy" src={image1} alt="" />
                          <p><span className="purple-holder">Unlimited team accounts</span></p>
                        </div>
                        <div className="check">
                          <img loading="lazy" src={image1} alt="" />
                          <p><span className="text-span-3">Unlimited Storage</span></p>
                        </div>
                        <div className="check">
                          <img loading="lazy" src={image1} alt="" />
                          <p className="purple-holder"><span className="text-span-4">Smart Contract</span></p>
                          
                        </div>
                        <div className="check">
                          <img loading="lazy" src={image1} alt="" />
                          <p className="purple-holder"><span className="text-span-9">Multi language & Currency</span></p>
                          
                        </div>
                      </div>
                      <Link data-ms-price-update="prc_enterprise-bdd04a2" to="/sign-up-today" className="button-2 big w-inline-block">
                        <p>Get Started</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Tab 2 */}
              <div data-w-tab="Tab 2" className={`yearly w-tab-pane ${activeTab === 'yearly' ? 'w--tab-active' : ''}`}>
                <div className="content-cards">

                 
      <div className="card">
        <div className="card-wrap">
          <div className="card-top">
            <div className="card-heading">
              <h2 className="mb-20">Basic</h2>
              <p className="paragraph-10">Ideal for retail stores just getting started with buybacks</p>
            </div>
            <div className="card-price">
              <p className="mb-5"><span className="strike">$29/mo</span> <sup>$</sup><span className="p-22">25</span>/mo</p>
              <p className="p-12">billed annually at $300</p>
            </div>
          </div>
          <div className="card-bot">
            <div className="check">
              <img loading="lazy" src={image1} alt="" />
              <p><span className="purple-holder">Up to 5 team members</span></p>
            </div>
            <div className="check">
              <img loading="lazy" src={image1} alt="" />
              <p><span className="purple-holder">Up to 5 Store Locations</span></p>
            </div>
            <div className="check">
              <img loading="lazy" src={image1} alt="" />
              <p className="purple-holder"><span className="text-span-10">20GB Storage</span></p>
              
            </div>
            <div className="check no-mb">
              <img loading="lazy" src={image1} alt="" />
              <p><span>Website Widget</span></p>
            </div>
            <div className="check no-mb">
              <img loading="lazy" src={image1} alt="" />
              <p className="paragraph-4"><span>Unlimited Product Variants</span></p>
            </div>
            <div className="check no-mb">
              <img loading="lazy" src={image1} alt="" />
              <p><span>Basic API Integrations</span></p>
            </div>
            <div className="check no-mb">
              <img loading="lazy" src={image1} alt="" />
              <p><span>iFrame Integration</span></p>
            </div>
          </div>
          <Link data-ms-price-update="prc_basic-n06e0or8" to="/sign-up-today" className="button-2 big w-inline-block">
            <p>Get Started</p>
          </Link>
        </div>
      </div>

      <div className="card mid">
        <div className="card-wrap mid">
          <div className="card-top">
            <div className="card-heading">
              <h2 className="mb-20">Plus</h2>
              <p className="paragraph-11">Perfect for retail or online companies looking to grow their buyback program</p>
            </div>
            <div className="card-price">
              <p className="mb-5"><span className="strike">$65/mo</span> <sup>$</sup><span className="p-22">52</span>/mo</p>
              <p className="p-12">billed annually at $624</p>
            </div>
          </div>
          <div className="card-bot">
            <div className="check">
              <img loading="lazy" src={image2} alt="" />
              <p><span className="purple-holder">Up to 20 team members</span></p>
            </div>
            <div className="check">
              <img loading="lazy" src={image2} alt="" />
              <p><span className="purple-holder">Unlimited Store Locations</span></p>
            </div>
            <div className="check">
              <img loading="lazy" src={image2} alt="" />
              <p className="purple-holder"><span className="text-span-11">100GB Storage</span></p>
              
            </div>
            <div className="check no-mb">
              <img loading="lazy" src={image2} alt="" />
              <p><span>Everything in Basic</span></p>
            </div>
            <div className="check no-mb">
              <img loading="lazy" src={image2} alt="" />
              <p><span>24/7 priority support</span></p>
            </div>
            <div className="check no-mb">
              <img loading="lazy" src={image2} alt="" />
              <p><span>Bulk Import/ Export</span></p>
            </div>
            <div className="check no-mb">
              <img loading="lazy" src={image2} alt="" />
              <p><span>Remove FurLoop branding</span></p>
            </div>
           
          </div>
          <Link data-ms-price-update="prc_plus-pb6f0lbu" to="/sign-up-today" className="button-2 big w-inline-block">
            <p>Get Started</p>
          </Link>
        </div>
      </div>

      <div className="card">
        <div className="card-wrap">
          <div className="card-top">
            <div className="card-heading">
              <h2 className="mb-20">Enterprise</h2>
              <p className="paragraph-12">For processors, marketplaces, resellers & enterprise organizations looking to fully white label.</p>
            </div>
            <div className="card-price">
              <p className="mb-5"><span className="p-22">Contact</span> Us</p>
              
            </div>
          </div>
          <div className="card-bot">
            <div className="check">
              <img loading="lazy" src={image1} alt="" />
              <p><span className="purple-holder">Everything in Plus</span></p>
            </div>
            <div className="check">
              <img loading="lazy" src={image1} alt="" />
              <p><span className="purple-holder">Custom Installation</span></p>
            </div>
            <div className="check">
              <img loading="lazy" src={image1} alt="" />
              <p><span className="purple-holder">Unlimited team accounts</span></p>
            </div>
            <div className="check">
              <img loading="lazy" src={image1} alt="" />
              <p><span className="text-span-14">Unlimited Storage</span></p>
            </div>
            <div className="check">
              <img loading="lazy" src={image1} alt="" />
              <p className="purple-holder"><span className="text-span-12">Smart Contract</span></p>
              
            </div>
            <div className="check">
              <img loading="lazy" src={image1} alt="" />
              <p className="purple-holder"><span className="text-span-13">Multi language & Currency</span></p>
              
            </div>
          </div>
          <Link data-ms-price-update="prc_enterprise-3e6g0lt7" to="/sign-up-today" className="button-2 big w-inline-block">
            <p>Get Started</p>
          </Link>
        </div>
      </div>
    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </>
  );
};

const Feature = ({ text, isUnderlined = false }) => (
  <div className="check no-mb">
    <img
      loading="lazy"
      src="https://cdn.prod.website-files.com/648422913bdb661f6232a4c4/66ae54216e0ba0a6e1c25c4e_Group%201000002493.svg"
      alt=""
    />
    <p className={isUnderlined ? 'underline' : ''}>
      <span className="text-span-6">{text}</span>
    </p>
  </div>
);

export default PriceSection;
