import React, { useState } from 'react';
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css'; 

const FAQSection = () => {
  const [activeIndices, setActiveIndices] = useState([]);
  const toggleFAQ = (index) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter(i => i !== index));
    } else {
      setActiveIndices([...activeIndices, index]);
    }
  };

 
  const faqData = [
    {
      question: 'What does FurLoop do?',
      answer: 'FurLoop helps businesses automate the buyback/trade-in process.',
    },
    {
      question: 'How does the widget work?',
      answer: 'We provide a dashboard where you can manage your sell orders, create products, and set prices based on the condition of items you are willing to accept. Customers can input their shipping and payment details on your site, helping to automate the buyback process and reduce the need for repetitive human interactions.',
    },
    {
      question: 'What happens after a customer creates a sell order?',
      answer: 'Once a customer has created a sell order on your site using the widget, both the customer and the merchant will be able to track the status, from awaiting shipment to delivered to completed.',
    },
    {
      question: 'What are we offering?',
      answer: 'We offer a SaaS solution that supports merchants, allowing them to offer a trade-in program by integrating the buyback widget on their site.',
    },
    {
      question: 'Is your website compatible?',
      answer: 'Yes, you can use our platform with any website builder, such as Shopify, Wix, WordPress, and Squarespace. Simply copy and paste your widget code on the page where you want it displayed.',
    },
    {
      question: 'Do you provide a demo?',
      answer: 'Yes, we do. Please request a demo through email, and our team will walk you through the dashboard and embedding process. It’s super quick and easy!',
    },
  ];


  const firstColumnData = faqData.slice(0, 3);
  const secondColumnData = faqData.slice(3);

  return (
    
    <>
 <div className="main-section-header">
      <div className="main-container">
        <div id="w-node-_282f81a1-557a-1f3d-aa2b-6f5ffdf3c5fa-6232a524" className="content-wrap">
          <h2 className="trade-in-header">Have a question? Get an answer!</h2>
          <div className="spacer_xs"></div>
          <p className="paragraph">
            For any additional questions, feel free to contact us via the form provided above.
          </p>
        </div>
        <div className="spacer_xxl"></div>
        <div className="grid_2-4 vertical-mobile">
          {faqData.map((faq, index) => (
            <div key={index} className="wrap_accordion">
              <div className="faq-item">
                <a
                  className="faq-question w-inline-block"
                  onClick={() => toggleFAQ(index)}
                >
                  <div><strong>{faq.question}</strong></div>
                  <div className="wrap_p-m">
                  <div className={`minus ${activeIndices.includes(index) ? 'visible' : ''}`}></div>
                <div className={`plus ${activeIndices.includes(index) ? 'hidden' : 'visible'}`}></div>
             </div>
                </a>
                {activeIndices.includes(index) && (
                  <div className="faq-answer">
                    <p className="faq-p">{faq.answer}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>


    
    </>
  );
};

export default FAQSection;
