import React, { useState } from 'react';
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css';
import image1 from '../images/pexels-marc-mueller-380768-p-500.jpeg';
import image2 from '../images/pexels-olia-danilevich-5313161.jpg';

const AboutUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    enquiryReason: '',
    phone: '',
    message: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setResponseMessage(''); 

    try {
      const response = await fetch('https://app.furloop.io/api/reseller/enquiry.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setResponseMessage(data.message);
        setIsSuccess(true);
        setFormData({ // Reset form fields
          firstName: '',
          lastName: '',
          email: '',
          enquiryReason: '',
          phone: '',
          message: ''
        });

        // Hide success message after 5 seconds
        setTimeout(() => {
          setResponseMessage('');
        }, 5000);

      } else {
        setResponseMessage(data.message);
        setIsSuccess(false); 
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setResponseMessage('Failed to submit the form. Please try again later.');
      setIsSuccess(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <section className="main-section-header">
        <div className="main-container">
          <div className="content-wrap_center">
            <div id="about-us-content" className="content-wrap_center mw-800">
              <div className="subheader cta-color">About us</div>
              <div className="spacer_xs"></div>
              <h1 className="h1">A company you can trust</h1>
              <div className="spacer_xs"></div>
              <p className="paragraph">
                We are a leading recommerce company specialising in e-waste solutions.
                Our comprehensive offerings range from retail trade-in programs to tailored
                white-label solutions, and from dedicated e-waste disposal services to corporate
                partnership programs. We are dedicated to creating sustainable, scalable, and
                efficient strategies for e-waste management.
              </p>
              <div className="spacer_l"></div>
              <a href="#" className="button w-button">Get started</a>
            </div>
            <div className="spacer_xxl"></div>
            <img
              className="hero-image"
              src={image1}
              alt="E-waste Solutions"
              sizes="90vw"
              srcSet={`${image1} 500w, ${image1} 800w, ${image1} 1080w, ${image1} 1600w, ${image1} 1920w`}
              loading="lazy"
            />
          </div>
        </div>
      </section>

      <section className="main-section-header">
        <div className="main-container">
          <div className="w-layout-grid grid_1-2">
            <div id="why-we-started-content" className="content-wrap">
              <h2 className="trade-in-header">Why we started</h2>
              <div className="spacer_m"></div>
              <p className="paragraph">
                Our mission is to revolutionise the life cycle of electronic devices, thereby
                mitigating environmental impact and simultaneously delivering value to both
                businesses and consumers. <br /><br />
                Our founders hold a strong conviction that every device merits a second chance,
                and we are dedicated to facilitating that transformation for you.
              </p>
              <div className="spacer_l"></div>
              <a href="#" className="button w-button">Roadmap</a>
            </div>
            <img
              className="hero-image"
              src={image2}
              alt="E-waste Management"
              sizes="90vw"
              srcSet={`${image2} 500w, ${image2} 800w, ${image2} 1080w, ${image2} 1280w`}
              loading="lazy"
            />
          </div>
        </div>
      </section>

      <section className="main-section-header">
      <div className="main-container">
        <div className="content-wrap_center">
          <div
            id="w-node-_6a3cdd91-5895-ae5b-0a58-b86d5fc1171d-6232a50b"
            data-w-id="6a3cdd91-5895-ae5b-0a58-b86d5fc1171d"
            className="content-wrap_center mw-800"
          >
            <h2 className="trade-in-header">Our Team</h2>
            <div className="spacer_xs"></div>
            <p className="paragraph"></p>
          </div>
          <div className="spacer_xxl"></div>
          <div className="w-layout-grid grid_1-4">
            <div
              id="w-node-_6a3cdd91-5895-ae5b-0a58-b86d5fc11725-6232a50b"
              data-w-id="6a3cdd91-5895-ae5b-0a58-b86d5fc11725"
              // style={{ opacity: 0 }}
              className="card-outer"
            >
              <div className="card-inner">
                <a href="#" className="social-link w-inline-block">
                  <div className="social-icon w-embed">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
                        fill="CurrentColor"
                      ></path>
                    </svg>
                  </div>
                </a>
                <div className="spacer_xs"></div>
                <h3 className="h3">Evan Gan</h3>
                <div className="spacer_xs"></div>
                <p className="paragraph">CEO & Co-Founder</p>
              </div>
            </div>
            <div
              id="w-node-_2ff4bbc0-be5a-ca52-15fa-e2ed043cb47b-6232a50b"
              data-w-id="2ff4bbc0-be5a-ca52-15fa-e2ed043cb47b"
              // style={{ opacity: 0 }}
              className="card-outer"
            >
              <div className="card-inner">
                <a href="#" className="social-link w-inline-block">
                  <div className="social-icon w-embed">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
                        fill="CurrentColor"
                      ></path>
                    </svg>
                  </div>
                </a>
                <div className="spacer_xs"></div>
                <h3 className="h3">Ellie Chow</h3>
                <div className="spacer_xs"></div>
                <p className="paragraph">CMO & Co-Founder</p>
              </div>
            </div>
            <div
              id="w-node-_05368036-acc4-0825-f625-98a3b6636259-6232a50b"
              data-w-id="05368036-acc4-0825-f625-98a3b6636259"
              // style={{ opacity: 0 }}
              className="card-outer"
            >
              <div className="card-inner">
                <a href="#" className="social-link w-inline-block">
                  <div className="social-icon w-embed">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
                        fill="CurrentColor"
                      ></path>
                    </svg>
                  </div>
                </a>
                <div className="spacer_xs"></div>
                <h3 className="h3">Jake Leow</h3>
                <div className="spacer_xs"></div>
                <p className="paragraph">Director & Co-Founder</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* contact section start  */}
    <section className="main-section-header">
      <div className="main-container">
        <div className="w-layout-grid grid_1-2 _75-space">
          <div className="content-wrap">
            <h2 className="trade-in-header">Have a question for us?</h2>
            <div className="spacer_m"></div>
            <p className="paragraph">
              Fill in the form below and one of our friendly staff will get back to you shortly.
            </p>
            <div className="spacer_l"></div>
            <a href="#" className="button secondary w-button">Get started</a>
          </div>
          <div className="w-form">
          {responseMessage && (
              <div
                className="response-message"
                style={{
                  backgroundColor: isSuccess ? 'green' : 'red',
                  color: 'white',
                  padding: '1rem',
                  borderRadius: '5px',
                  marginBottom: '1rem',
                }}
              >
                {responseMessage}
              </div>
            )}
            <form
              id="wf-form-Contact-Form"
              name="wf-form-Contact-Form"
              onSubmit={handleSubmit}
              className="form-inner"
            >
              <div className="flex-row_outer">
                <div className="input-wrapper _15p-right">
                  <label htmlFor="firstName" className="input-label">First Name</label>
                  <input
                    className="input w-input"
                    maxLength="256"
                    name="firstName"
                    placeholder="e.g. Howard"
                    type="text"
                    id="First-Name-2"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="lastName" className="input-label">Last Name</label>
                  <input
                    className="input w-input"
                    maxLength="256"
                    name="lastName"
                    placeholder="e.g. Thurman"
                    type="text"
                    id="Last-Name-2"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="flex-row_outer">
                <div className="input-wrapper _15p-right">
                  <label htmlFor="email" className="input-label">Email</label>
                  <input
                    className="input w-input"
                    maxLength="256"
                    name="email"
                    placeholder="e.g. howard.thurman@gmail.com"
                    type="email"
                    id="Email-3"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="phone" className="input-label">Phone</label>
                  <input
                    className="input w-input"
                    maxLength="256"
                    name="phone"
                    placeholder="e.g +61 412 345 657"
                    type="tel"
                    id="Phone-3"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label htmlFor="enquiryReason" className="input-label">Reason for enquiry</label>
                <select
                  id="enquiryReason"
                  name="enquiryReason"
                  className="input w-select"
                  value={formData.enquiryReason}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select one...</option>
                  <option value="Corporate Partnership">Corporate Partnership</option>
                  <option value="Retail Trade In Program">Retail Trade In Program</option>
                  <option value="Waste Solutions">Waste Solutions</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="input-wrapper">
                <label htmlFor="message" className="input-label">Message</label>
                <textarea
                  id="message"
                  name="message"
                  maxLength="5000"
                  placeholder="Write your message here.."
                  className="input w-input"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
              <div className="spacer_xs"></div>
              <input
                type="submit"
                className="button in-form w-button"
                value="Submit"
              />
            </form>
          </div>
        </div>
      </div>
    </section>

    </div>
  );
};

export default AboutUs;
