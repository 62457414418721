import React from 'react';
import '../css/MainSection.css'; // Import the CSS file for custom styles
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css'; 
import image1 from '../images/Group-1000002477.svg';
import image2 from '../images/Group-1000002473.svg';
import image3 from '../images/Group-1000002476.svg';
import image4 from '../images/Group-1000002474.svg';
import image5 from '../images/Group-1000002466-1.svg';
import image6 from '../images/Group-1000002478.svg';
import image7 from '../images/Group-1000002469.svg';

const MainSection = () => {
 
  return (
<>
<div className="main-section-header">
  <div className="main-container">
    <div className="content-wrap_center">
      <div
        id="w-node-a0f4c818-4abb-ddfd-6f6c-1c2a34baaa78-6232a524"
        data-w-id="a0f4c818-4abb-ddfd-6f6c-1c2a34baaa78"
        className="content-wrap_center mw-800"
      >
        <h1 className="trade-in-header-testt">Trade-In Made Simple, Worldwide.</h1>
        <div className="spacer_xs"></div>
        <p className="paragraph-intro"></p>
      </div>
      <div className="text-block-3">
        FurLoop powers businesses across the globe to streamline their online and retail trade-in lead generation and operations, enabling them to focus on driving growth and success.
      </div>
      <div
        style={{ paddingTop: "56.17021276595745%" }}
        className="video w-video w-embed"
      >
        <iframe
          className="embedly-embed"
          src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4RcYiKkSV8w%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4RcYiKkSV8w&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4RcYiKkSV8w%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube"
          width="940"
          height="528"
          scrolling="no"
          allowFullScreen
          title="Saas Dashboard Preview"
        ></iframe>
      </div>
      
    </div>
    <div className="w-layout-blockcontainer container-trade-in w-container">
      <section className="div-desk-upper">
        <address className="w-layout-hflex flex-block-upper">
          <img
            src={image1}
            loading="lazy"
            width="670"
            alt=""
            className="image-3"
          />
          <img
            src={image2}
            loading="lazy"
            width="500"
            alt=""
            className="image-2"
          />
        </address>
      </section>
      <div className="div-upper-mobile">
        <div className="w-layout-hflex flex-block-upper-mobile">
          <img
            src="../images/Group-1000002478.svg"
            src={image6}
            loading="lazy"
            width="Auto"
            alt=""
            className="image-3"
          />
          <img
            src="../images/Group-1000002469.svg"
            src={image7}
            loading="lazy"
            width="Auto"
            alt=""
            className="image-3"
          />
        </div>
      </div>
      <div className="div-desk-lower">
        <div className="w-layout-hflex flex-block-lower">
          <img
            src={image3}
            loading="lazy"
            width="460"
            alt=""
            className="image-3"
          />
          <img
            src={image4}
            loading="lazy"
            width="600"
            alt=""
            className="image-2"
          />
        </div>
      </div>
      <div className="div-desk-lower-mobile">
        <div className="w-layout-hflex flex-block-lower-mobile">
          <img
            src="../images/Group-1000002467.svg"
            src={image3}
            loading="lazy"
            width="Auto"
            alt=""
            className="image-3"
          />
          <img
            src="../images/Group-1000002466-1.svg"
            src={image5}
            loading="lazy"
            width="Auto"
            alt=""
            className="image-2"
          />
        </div>
      </div>
    </div>
  </div>
</div>


    </>
  );
};

export default MainSection;
