import React from 'react';
import HeroSection from './HeroSection';
import Brand from './Brand';
import MainSection from './MainSection';
import FeatureSection from './FeatureSection';
import PriceSection from './PriceSection';
import ContactSection from './ContactSection';
import FAQSection from './FAQSection';


function Home() {
  return (
    <div>
    <HeroSection />
    <Brand />
    <MainSection />
    <FeatureSection />
    <PriceSection />
    <ContactSection />
    <FAQSection />
    </div>
   
  );
}

export default Home;
